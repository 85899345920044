import React, { Fragment } from "react";
import { graphql } from "gatsby";

import { getProperty } from "@zeus/shared";

import { Meta } from "./common/meta";
import { Teaser } from "./home/teaser";
import { Zeus } from "./home/zeus";
import { Training } from "./home/training";
import { Studio } from "./home/studio";
import { Facts } from "./home/facts";
import { Location } from "./home/location";

const Template = ({ data }) => {
  const teaser = getProperty(data, "markdownRemark.frontmatter.home.teaser");
  const zeus = getProperty(data, "markdownRemark.frontmatter.home.zeus");
  const training = getProperty(data, "markdownRemark.frontmatter.home.training");
  const studio = getProperty(data, "markdownRemark.frontmatter.home.studio");
  const facts = getProperty(data, "markdownRemark.frontmatter.home.facts");
  const location = getProperty(data, "markdownRemark.frontmatter.home.location");
  const meta = getProperty(data, "markdownRemark.frontmatter.meta");

  return (
    <Fragment>
      <Meta data={meta} />
      <Teaser data={teaser} />
      <Zeus data={zeus} />
      <Training data={training} />
      <Studio data={studio} />
      <Facts data={facts} />
      <Location data={location} />
    </Fragment>
  );
};

export default Template;

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        path
        title

        meta {
          title
          description
        }

        home {
          teaser {
            text
          }

          zeus {
            title
            content
            background {
              title
              image {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }

          training {
            title
            content
            effort {
              value
              unit
            }
            usp {
              label
              value
            }
            benefits {
              title
              items
              footer
            }
          }

          studio {
            title
            background {
              title
              image {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
            grid {
              title
              content
              images {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 768, height: 768)
                }
              }
            }
          }

          facts {
            title
            items {
              title
              content
            }
            images {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 768)
              }
            }
          }

          location {
            title
            directions {
              text
              url
            }
            info
          }
        }
      }
    }
  }
`;
