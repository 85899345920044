// extracted by mini-css-extract-plugin
export var breakpoint_xs = "30rem";
export var breakpoint_sm = "48rem";
export var breakpoint_md = "64rem";
export var breakpoint_lg = "75rem";
export var breakpoint_xl = "90rem";
export var color_primary = "#00a3a6";
export var color_secondary = "#070707";
export var holder = "training-module--holder--V-7qU";
export var content = "training-module--content--4KckB";
export var effort = "training-module--effort--0O3Ah";
export var value = "training-module--value--EbXwo";
export var unit = "training-module--unit--WAXSM";
export var usp = "training-module--usp--ogVhH";
export var item = "training-module--item--Enbxk";
export var title = "training-module--title--+lCA7";
export var benefits = "training-module--benefits--0MCwB";
export var items = "training-module--items--EOsVy";
export var footer = "training-module--footer--ggdac";
export var icon = "training-module--icon--9Pdko";