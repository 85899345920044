// extracted by mini-css-extract-plugin
export var breakpoint_xs = "30rem";
export var breakpoint_sm = "48rem";
export var breakpoint_md = "64rem";
export var breakpoint_lg = "75rem";
export var breakpoint_xl = "90rem";
export var color_primary = "#00a3a6";
export var color_secondary = "#070707";
export var header = "studio-module--header--bsqnR";
export var title = "studio-module--title--49VE6";
export var background = "studio-module--background--2RbEF";
export var words = "studio-module--words---wKSI";
export var holder = "studio-module--holder--rBHca";
export var image = "studio-module--image--ebLFz";
export var content = "studio-module--content--iIKej";
export var grid = "studio-module--grid--hIuXZ";
export var item = "studio-module--item--ZXfEb";
export var text = "studio-module--text--ayVMo";
export var image1 = "studio-module--image1--lyWWE";
export var image2 = "studio-module--image2--GlJEk";
export var image3 = "studio-module--image3--nUleH";