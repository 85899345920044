// extracted by mini-css-extract-plugin
export var breakpoint_xs = "30rem";
export var breakpoint_sm = "48rem";
export var breakpoint_md = "64rem";
export var breakpoint_lg = "75rem";
export var breakpoint_xl = "90rem";
export var color_primary = "#00a3a6";
export var color_secondary = "#070707";
export var header = "zeus-module--header--sTQ5V";
export var title = "zeus-module--title--uXArQ";
export var content = "zeus-module--content--ltQXR";
export var statement = "zeus-module--statement--7LiS8";
export var active = "zeus-module--active--MHUGw";
export var background = "zeus-module--background--USe81";
export var holder = "zeus-module--holder--jq9r5";
export var image = "zeus-module--image--foL+6";
export var words = "zeus-module--words--sdoLh";
export var word = "zeus-module--word--omnqb";