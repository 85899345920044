import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { gsap } from "gsap/all";

import { Section, Container, Title, Text, Markdown, getProperty, MapIcon } from "@zeus/shared";

import { useMeta } from "hooks";

import * as styles from "./location.module.scss";

export const Location = ({ data }) => {
  const meta = useMeta();
  const mapRef = useRef();

  const address = getProperty(meta, "contact.address");

  const title = getProperty(data, "title");
  const directionsText = getProperty(data, "directions.text");
  const directionsUrl = getProperty(data, "directions.url");
  const info = getProperty(data, "info");

  useEffect(() => {
    const { current: mapContainer } = mapRef;
    const svg = mapContainer.querySelectorAll(`.${CSS.escape(styles.svg)}`);
    const directions = mapContainer.querySelectorAll(`.${CSS.escape(styles.directions)}`);

    const timeline = gsap
      .timeline({
        scrollTrigger: {
          pin: true,
          scrub: true,
          start: "top 25%",
          end: "bottom -=100%",
          trigger: mapContainer,
        },
      })
      .from(svg, { duration: 0.75, scale: 4, alpha: 0.25 })
      .from(directions, { duration: 0.25, x: 50, alpha: 0, force3D: true });

    return () => timeline.kill();
  }, []);

  return (
    <Section id="lage" size="lg" className={styles.section}>
      <div className={styles.header}>
        <Container>
          <Title wrapper="h4" transform="inherit" gradient={true} className={styles.title}>
            {title}
          </Title>
        </Container>
      </div>

      <div className={styles.map} ref={mapRef}>
        <div className={styles.directions}>
          <Container>
            <Text size="s3" transform="uppercase">
              <a href={directionsUrl} className={styles.link} target="_blank" rel="noreferrer">
                {directionsText} &rarr;
              </a>
            </Text>
          </Container>
        </div>
        <MapIcon className={styles.svg} />
      </div>

      <Container>
        <div className={styles.footer}>
          <div className={styles.info}>
            <Text size="s7" transform="uppercase">
              <Markdown content={address} wrapper="address" />
            </Text>
            <Text size="s3" transform="uppercase" color="primary">
              <Markdown content={info} />
            </Text>
          </div>
        </div>
      </Container>
    </Section>
  );
};

Location.defaultProps = {
  data: {},
};

Location.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    background: PropTypes.shape({
      image: PropTypes.object.isRequired,
      title: PropTypes.string.isRequired,
    }),
    grid: PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    }),
  }).isRequired,
};
