// extracted by mini-css-extract-plugin
export var breakpoint_xs = "30rem";
export var breakpoint_sm = "48rem";
export var breakpoint_md = "64rem";
export var breakpoint_lg = "75rem";
export var breakpoint_xl = "90rem";
export var color_primary = "#00a3a6";
export var color_secondary = "#070707";
export var section = "location-module--section--nhXqQ";
export var header = "location-module--header--ShmjY";
export var title = "location-module--title--74Sw1";
export var map = "location-module--map--ILUOv";
export var directions = "location-module--directions--WQ0xk";
export var link = "location-module--link--74CyR";
export var svg = "location-module--svg--+yUzY";
export var footer = "location-module--footer--yyKyJ";
export var info = "location-module--info--qS9Lq";