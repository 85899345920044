import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import { gsap } from "gsap/all";

import { Section, Container, Title, Text, Markdown, getProperty } from "@zeus/shared";

import * as styles from "./studio.module.scss";

export const Studio = ({ data }) => {
  const backgroundRef = useRef();
  const gridRef = useRef();

  const title = getProperty(data, "title");

  const backgroundTitle = getProperty(data, "background.title");
  const backgroundImage = getProperty(data, "background.image.childImageSharp.gatsbyImageData");

  const gridTitle = getProperty(data, "grid.title");
  const gridContent = getProperty(data, "grid.content");

  const impressionImages = getProperty(data, "grid.images") || [];
  const [gridImage1, gridImage2, gridImage3] = impressionImages.map((image) =>
    getProperty(image, "childImageSharp.gatsbyImageData")
  );

  useEffect(() => {
    const { current: container } = backgroundRef;
    const image = container.querySelectorAll(`.${CSS.escape(styles.image)}`);
    const holder = container.querySelectorAll(`.${CSS.escape(styles.holder)}`);

    const timeline = gsap
      .timeline({
        scrollTrigger: {
          pin: true,
          scrub: true,
          start: "top top",
          end: "bottom -=100%",
          trigger: container,
        },
      })
      .set(image, { "--circle-x": "0%", "--gradient-to": "25%" })
      .to(image, { "--circle-x": "100%" })
      .to(image, { "--circle-x": "50%" })
      .to(image, { "--gradient-to": "100%" })
      .from(holder, { y: "50%", opacity: 0 });

    return () => timeline.kill();
  }, []);

  useEffect(() => {
    const { current: container } = gridRef;
    const images = container.querySelectorAll(`.${CSS.escape(styles.image)}`);
    const text = container.querySelectorAll(`.${CSS.escape(styles.text)} > *`);

    const animationsParallax = [...images].map((trigger, index) =>
      gsap.from(trigger, {
        y: "40%",
        force3D: true,
        scrollTrigger: {
          trigger,
          scrub: 0.5 + index,
        },
      })
    );

    const animationsReveals = [...text].map((trigger, index) =>
      gsap.from(trigger, {
        y: 100,
        alpha: 0,
        force3D: true,
        scrollTrigger: {
          trigger,
          scrub: true,
          start: "top 90%",
          end: "bottom 60%",
        },
      })
    );

    return () =>
      [...animationsParallax, ...animationsReveals].forEach((animation) => animation.kill());
  }, []);

  return (
    <Section id="studio" size="lg" noPaddingBottom={true}>
      <div className={styles.header}>
        <Container>
          <Title gradient={true} className={styles.title}>
            {title}
          </Title>
        </Container>
      </div>
      <div className={styles.background} ref={backgroundRef}>
        <div className={styles.holder}>
          <Container>
            <Text size="s10" family="f2" transform="uppercase" className={styles.words}>
              <Markdown content={backgroundTitle} inline={true} />
            </Text>
          </Container>
        </div>
        <GatsbyImage image={backgroundImage} className={styles.image} alt="Studio" />
      </div>

      <div className={styles.content}>
        <Container>
          <div className={styles.grid} ref={gridRef}>
            <div className={styles.item}>
              <GatsbyImage
                image={gridImage1}
                className={`${styles.image} ${styles.image1}`}
                alt="Impression"
              />
            </div>
            <div className={styles.item}>
              <GatsbyImage
                image={gridImage2}
                className={`${styles.image} ${styles.image2}`}
                alt="Impression"
              />
            </div>
            <div className={styles.item}>
              <div className={styles.text}>
                <Title wrapper="h3">{gridTitle}</Title>
                <Text>
                  <Markdown content={gridContent} />
                </Text>
              </div>
            </div>
            <div className={styles.item}>
              <GatsbyImage
                image={gridImage3}
                className={`${styles.image} ${styles.image3}`}
                alt="Impression"
              />
            </div>
          </div>
        </Container>
      </div>
    </Section>
  );
};

Studio.defaultProps = {
  data: {},
};

Studio.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    background: PropTypes.shape({
      image: PropTypes.object.isRequired,
      title: PropTypes.string.isRequired,
    }),
    grid: PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    }),
  }).isRequired,
};
