import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { gsap } from "gsap/all";

import { Section, Container, Title, Text, Markdown, FlashIcon, getProperty } from "@zeus/shared";

import * as styles from "./training.module.scss";

export const Training = ({ data }) => {
  const uspRef = useRef();
  const contentRef = useRef();
  const benefitsRef = useRef();

  const title = getProperty(data, "title");
  const content = getProperty(data, "content");

  const effortValue = getProperty(data, "effort.value");
  const effortUnit = getProperty(data, "effort.unit");

  const uspItems = getProperty(data, "usp") || [];

  const benefitsTitle = getProperty(data, "benefits.title");
  const benefitsItems = getProperty(data, "benefits.items") || [];
  const benefitsFooter = getProperty(data, "benefits.footer");

  useEffect(() => {
    const { current: container } = contentRef;
    const items = container.querySelectorAll(`.${CSS.escape(styles.content)} > *`);

    const animations = [...items].map((trigger, index) =>
      gsap.from(trigger, {
        y: 100,
        alpha: 0,
        force3D: true,
        scrollTrigger: {
          trigger,
          start: "top 90%",
          end: "bottom 60%",
          scrub: 0.5 + 0.25 * index,
        },
      })
    );

    return () => animations.forEach((animation) => animation.kill());
  }, []);

  useEffect(() => {
    const { current: container } = uspRef;
    const items = container.querySelectorAll(`.${CSS.escape(styles.item)}`);

    const animations = [...items].map((trigger, index) =>
      gsap.from(trigger, {
        x: 100,
        alpha: 0,
        force3D: true,
        scrollTrigger: {
          trigger,
          start: "top 90%",
          end: "bottom 60%",
          scrub: 0.5 + 0.25 * index,
        },
      })
    );

    return () => animations.forEach((animation) => animation.kill());
  }, []);

  useEffect(() => {
    const { current: container } = benefitsRef;
    const lines = container.querySelectorAll(`.${CSS.escape(styles.item)}`);
    const title = container.querySelectorAll(`.${CSS.escape(styles.title)}`);
    const footer = container.querySelectorAll(`.${CSS.escape(styles.footer)}`);
    const icon = container.querySelectorAll(`.${CSS.escape(styles.icon)}`);

    const animationsHorizontal = [...lines].map((trigger, index) =>
      gsap.from(trigger, {
        x: 100 * (index % 2 === 0 ? 1 : -1),
        alpha: 0,
        force3D: true,
        scrollTrigger: {
          trigger,
          scrub: true,
          start: "top 90%",
          end: "bottom 60%",
        },
      })
    );

    const animationsVertical = [...title, ...footer, ...icon].map((trigger, index) =>
      gsap.from(trigger, {
        y: 100,
        alpha: 0,
        force3D: true,
        scrollTrigger: {
          trigger,
          scrub: true,
          start: "top 90%",
          end: "bottom 60%",
        },
      })
    );

    return () =>
      [...animationsHorizontal, ...animationsVertical].forEach((animation) => animation.kill());
  }, []);

  return (
    <Section id="training" size="lg" noPaddingBottom={true}>
      <Container>
        <div className={styles.holder}>
          <div className={styles.content} ref={contentRef}>
            <Title wrapper="h3">{title}</Title>
            <Text>
              <Markdown content={content} />
            </Text>
            <div className={styles.effort}>
              <Text size="s10" className={styles.value}>
                {effortValue}
              </Text>
              <Text size="s8" transform="uppercase" className={styles.unit}>
                {effortUnit}
              </Text>
            </div>
          </div>

          <div className={styles.usp} ref={uspRef}>
            {uspItems.map((item, index) => {
              const label = getProperty(item, "label");
              const value = getProperty(item, "value");

              return (
                <div key={index} className={styles.item}>
                  <Title
                    size="s4"
                    weight="w5"
                    family="f1"
                    wrapper="h4"
                    color="primary"
                    transform="inherit"
                    className={styles.title}>
                    {label}
                  </Title>
                  <Text size="s5">
                    <strong>{value}</strong>
                  </Text>
                </div>
              );
            })}
          </div>
        </div>

        <div className={styles.benefits} ref={benefitsRef}>
          <Title wrapper="h4" gradient={true} className={styles.title}>
            <Markdown content={benefitsTitle} inline={true} wrapper="span" />
          </Title>
          <div className={styles.items}>
            {benefitsItems.map((item, index) => (
              <Text key={index} size="s5" className={styles.item}>
                <Markdown content={item} />
              </Text>
            ))}
          </div>
          <Text color="primary" className={styles.footer}>
            {benefitsFooter}
          </Text>
          <FlashIcon className={styles.icon} />
        </div>
      </Container>
    </Section>
  );
};

Training.defaultProps = {
  data: {},
};

Training.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    effort: PropTypes.shape({
      value: PropTypes.number.isRequired,
      unit: PropTypes.string.isRequired,
    }),
    usp: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
    benefits: PropTypes.shape({
      title: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      footer: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
