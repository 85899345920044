import React, { useMemo, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import { gsap } from "gsap/all";

import { Section, Container, Title, Text, Markdown, getProperty } from "@zeus/shared";

import * as styles from "./facts.module.scss";

export const Facts = ({ data }) => {
  const gridRef = useRef();

  const title = getProperty(data, "title");

  const items = useMemo(() => {
    const items = getProperty(data, "items") || [];
    const images = getProperty(data, "images") || [];

    return items.reduce(
      (rows, item, index) => [
        ...rows,
        ...[[item, getProperty(images, `${index}.childImageSharp.gatsbyImageData`)]],
      ],
      []
    );
  }, [data]);

  useEffect(() => {
    const { current: container } = gridRef;
    const items = container.querySelectorAll(`.${CSS.escape(styles.item)}`);

    const animations = [...items].map((trigger, index) =>
      gsap.from(trigger, {
        x: 100 * (index % 2 === 0 ? -1 : 1),
        alpha: 0,
        force3D: true,
        scrollTrigger: {
          trigger,
          start: "top 90%",
          end: "bottom 60%",
          scrub: 0.5 + 0.25 * index,
        },
      })
    );

    return () => animations.forEach((animation) => animation.kill());
  }, []);

  return (
    <Section id="fakten" size="lg">
      <Container>
        <div className={styles.header}>
          <Title gradient={true} className={styles.title}>
            {title}
          </Title>
        </div>
        <div className={styles.grid} ref={gridRef}>
          {items.map(([text, image], index) => {
            const title = getProperty(text, "title");
            const content = getProperty(text, "content");

            return (
              <div key={index} className={styles.item}>
                <div className={styles.text}>
                  <Title
                    size="s4"
                    weight="w5"
                    family="f1"
                    wrapper="h4"
                    color="primary"
                    transform="inherit"
                    className={styles.title}>
                    {title}
                  </Title>
                  <Text size="s5">
                    <Markdown content={content} />
                  </Text>
                </div>
                {image && <GatsbyImage image={image} className={styles.image} alt="Fact" />}
              </div>
            );
          })}
        </div>
      </Container>
    </Section>
  );
};

Facts.defaultProps = {
  data: {},
};

Facts.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
      })
    ).isRequired,
    images: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  }).isRequired,
};
