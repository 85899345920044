// extracted by mini-css-extract-plugin
export var breakpoint_xs = "30rem";
export var breakpoint_sm = "48rem";
export var breakpoint_md = "64rem";
export var breakpoint_lg = "75rem";
export var breakpoint_xl = "90rem";
export var color_primary = "#00a3a6";
export var color_secondary = "#070707";
export var content = "teaser-module--content--T52YM";
export var logo = "teaser-module--logo--b-ws-";
export var animation = "teaser-module--animation--d8yPF";
export var icon = "teaser-module--icon--sTKRS";
export var welcome = "teaser-module--welcome--xL5wp";
export var social = "teaser-module--social--9TPUg";
export var appointment = "teaser-module--appointment--T6Wrn";
export var more = "teaser-module--more--fbrpF";
export var arrow = "teaser-module--arrow--Y-WSN";