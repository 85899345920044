import React, { useLayoutEffect, useRef } from "react";
import PropTypes from "prop-types";
import { gsap, ScrollTrigger } from "gsap/all";

import { Section, Text, FlashIcon, LogoSymbolIcon, Container, getProperty } from "@zeus/shared";

import { useMeta } from "hooks";
import { Appointment, SocialMedia } from "theme";

import * as styles from "./teaser.module.scss";


export const Teaser = ({ data }) => {
  const meta = useMeta();
  const logoRef = useRef();

  const text = getProperty(data, "text");
  const more = getProperty(meta, "more_link");

  useLayoutEffect(() => {
    const { current: trigger } = logoRef;

    const animation = ScrollTrigger.create({
      trigger,
      scrub: true,
      toggleClass: styles.animation,
      start: () => {
        const { top } = trigger.getBoundingClientRect();
        return `top ${top - 10}`;
      },
      end: () => {
        const { top } = trigger.getBoundingClientRect();
        return `bottom ${top - 10}`;
      },

    });

    return () => animation.kill();
  }, []);

  return (
    <Section noPaddingTop={true} noPaddingBottom={true}>
      <Container>
        <div className={styles.content}>
          <div className={styles.logo} ref={logoRef}>
            <LogoSymbolIcon className={styles.icon} />
          </div>
          <div className={styles.welcome}>
            <div className={styles.social}>
              <SocialMedia />
            </div>
            <Text size="s3" transform="uppercase" className={styles.text}>
              {text}
            </Text>
            <div className={styles.appointment}>
              <Appointment />
            </div>
          </div>

          <div className={styles.more}>
            <a href="#zeus" className={styles.arrow}>
              <FlashIcon className={styles.icon} />
              <Text size="s2" transform="uppercase">
                {more}
              </Text>
            </a>
          </div>
        </div>
      </Container>
    </Section>
  );
};

Teaser.defaultProps = {
  data: {},
};

Teaser.propTypes = {
  data: PropTypes.shape({
    text: PropTypes.string.isRequired,
  }).isRequired,
};
