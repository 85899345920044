import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import { gsap, ScrollTrigger } from "gsap/all";

import { Section, Container, Title, Text, Markdown, getProperty } from "@zeus/shared";

import * as styles from "./zeus.module.scss";

export const Zeus = ({ data }) => {
  const contentRef = useRef();
  const backgroundRef = useRef();

  const title = getProperty(data, "title");
  const content = getProperty(data, "content") || [];
  const backgroundTitle = getProperty(data, "background.title") || [];
  const backgroundImage = getProperty(data, "background.image.childImageSharp.gatsbyImageData");

  useEffect(() => {
    const { current: container } = contentRef;
    const lines = container.querySelectorAll(`.${CSS.escape(styles.statement)}`);

    const animations = [...lines].map((trigger, index) =>
      ScrollTrigger.create({
        trigger,
        scrub: 2,
        start: "top 55%",
        end: "bottom 45%",
        toggleClass: styles.active,
      })
    );

    return () => animations.forEach((animation) => animation.kill());
  }, []);

  useEffect(() => {
    const { current: container } = backgroundRef;
    const lines = container.querySelectorAll(`.${CSS.escape(styles.word)}`);

    const animations = [...lines].map((trigger, index) => {
      const factor = index % 2 === 0 ? 2 : -2;
      const x = `${(3 + Math.random()) * factor}%`;

      return gsap.to(trigger, {
        x,
        force3D: true,
        scrollTrigger: {
          trigger,
          scrub: true,
          start: "top 90%",
          end: "bottom 60%",
        },
      });
    });

    return () => animations.forEach((animation) => animation.kill());
  }, []);

  return (
    <Section id="zeus" size="lg" noPaddingBottom={true}>
      <div className={styles.header}>
        <Container>
          <Title wrapper="h1" gradient={true} className={styles.title}>
            <Markdown content={title} inline={true} wrapper="span" />
          </Title>
        </Container>
      </div>

      <div className={styles.content} ref={contentRef}>
        <Container>
          {content.map((line, index) => (
            <Text key={index} size="s5" className={styles.statement}>
              {line}
            </Text>
          ))}
        </Container>
      </div>
      <div className={styles.background} ref={backgroundRef}>
        <div className={styles.holder}>
          <Container>
            <div className={styles.words}>
              {backgroundTitle.map((line, index) => (
                <Text
                  key={index}
                  size="s6"
                  family="f2"
                  transform="uppercase"
                  className={styles.word}>
                  {line}
                </Text>
              ))}
            </div>
          </Container>
        </div>
        <GatsbyImage image={backgroundImage} className={styles.image} alt="Studio" />
      </div>
    </Section>
  );
};

Zeus.defaultProps = {
  data: {},
};

Zeus.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    background: PropTypes.object.isRequired,
  }).isRequired,
};
