// extracted by mini-css-extract-plugin
export var breakpoint_xs = "30rem";
export var breakpoint_sm = "48rem";
export var breakpoint_md = "64rem";
export var breakpoint_lg = "75rem";
export var breakpoint_xl = "90rem";
export var color_primary = "#00a3a6";
export var color_secondary = "#070707";
export var header = "facts-module--header--+Hswd";
export var title = "facts-module--title--jBbmP";
export var grid = "facts-module--grid--aiC5s";
export var item = "facts-module--item--UUF-z";
export var text = "facts-module--text--DPSus";
export var image = "facts-module--image--qM3Ct";